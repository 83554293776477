import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { tmdbClient } from "./client";
import type { TmdbTimeWindow } from "./types";

export const tmdbApi = createApi({
  reducerPath: "tmdbApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    // MARK: Movies

    getTrendingMovies: builder.query({
      queryFn: async ({
        timeWindow,
        language,
      }: {
        timeWindow: TmdbTimeWindow;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getTrendingMovies(timeWindow, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    searchMovies: builder.query({
      queryFn: async ({
        query,
        language,
      }: {
        query: string;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.searchMovies(query, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    getPopularMovies: builder.query({
      queryFn: async ({
        page,
        region,
        language,
      }: {
        page: number;
        region?: string;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getPopularMovies({ page, region }, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    // MARK: TV Series

    getTrendingTVSeries: builder.query({
      queryFn: async ({
        timeWindow,
        language,
      }: {
        timeWindow: TmdbTimeWindow;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getTrendingTVSeries(timeWindow, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    searchTVSeries: builder.query({
      queryFn: async ({
        query,
        language,
      }: {
        query: string;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.searchTVSeries(query, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    getPopularTVSeries: builder.query({
      queryFn: async ({
        page,
        region,
        language,
      }: {
        page: number;
        region?: string;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getPopularTVSeries(
              { page, region },
              language,
            ),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    // MARK: Genres
    getMoviesGenres: builder.query({
      queryFn: async ({ language }: { language: string }) => {
        try {
          return {
            data: await tmdbClient.getMoviesGenres({ language }),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    getSeriesGenres: builder.query({
      queryFn: async ({ language }: { language: string }) => {
        try {
          return {
            data: await tmdbClient.getSeriesGenres({ language }),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    // MARK: Search Multi
    searchMulti: builder.query({
      queryFn: async ({
        query,
        page,
        language,
      }: {
        query: string;
        page: number;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.searchMulti({ query, page }, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
  }),
});

export const {
  useGetTrendingMoviesQuery,
  useSearchMoviesQuery,
  useGetPopularMoviesQuery,
  useGetTrendingTVSeriesQuery,
  useSearchTVSeriesQuery,
  useGetPopularTVSeriesQuery,
  useSearchMultiQuery,
  useGetMoviesGenresQuery,
  useGetSeriesGenresQuery,
} = tmdbApi;
