"use client";

import { AppRouterCacheProvider } from "@mui/material-nextjs/v13-appRouter";
import dynamic from "next/dynamic";

import StoreProvider from "./store-provider";
import { StoreProvider as MobxStoreProvider } from "@/app/store/mobx-store-provider";
import { AppTheme } from "@/app/app-theme";
import { SnackStack } from "@/widgets/snack-stack/ui";
import { PreviousPathnameProvider } from "@/shared/lib/use-previous-path";

const AddToHomeScreen = dynamic(
  () =>
    import("@/features/add-to-home/ui/add-to-home").then(
      (m) => m.AddToHomeScreen,
    ),
  {
    ssr: false,
  },
);

export function Providers({ children }: React.PropsWithChildren) {
  return (
    <StoreProvider>
      <MobxStoreProvider>
        <AppRouterCacheProvider options={{ key: "css" }}>
          <AppTheme>
            <PreviousPathnameProvider>{children}</PreviousPathnameProvider>
          </AppTheme>
          <SnackStack />
          <AddToHomeScreen />
        </AppRouterCacheProvider>
      </MobxStoreProvider>
    </StoreProvider>
  );
}
